import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import SecondaryHero from "../../components/secondary-hero";
const ExecutiveCommitteePage = ({ transitionStatus }) => {
	const data = useStaticQuery(graphql`
		query {
			wpPageContent(id: { eq: "cG9zdDo2NzU=" }) {
				content
			}
			tImg: wpMediaItem(title: { eq: "Team Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: FULL_WIDTH
							placeholder: BLURRED
						)
					}
				}
			}
			allWpCommitteeMembers(
				sort: { fields: committeeMemberFields___order, order: ASC }
			) {
				nodes {
					committeeMemberFields {
						name
						title
						bio
						order
						section
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "About Us",
				item: {
					url: `${siteUrl}/about-us`,
					id: `${siteUrl}/about-us`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Executive committee",
				item: {
					url: `${siteUrl}/about-us/executive-committee`,
					id: `${siteUrl}/about-us/executive-committee`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Executive committee | Friends of Eastbourne Hospital"
				description="The faces behind the Friends, here is our current Executive Committee, and a little bit about them."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/about-us/executive-committee`,
					title: "Executive committee | Friends of Eastbourne Hospital",
					description:
						"The faces behind the Friends, here is our current Executive Committee, and a little bit about them.",
					images: [
						{
							url: `${data.tImg?.localFile.publicURL}`,
							width: `${data.tImg?.localFile.childImageSharp.original.width}`,
							height: `${data.tImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.tImg?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section className="pb-5">
					<SecondaryHero
						title="Executive committee"
						description={
							<span>
								The faces behind the Friends, here is our current Executive
								Committee, and a<br className="d-none d-md-block" /> little bit
								about them.
							</span>
						}
					/>
				</section>
				<section className="pb-5">
					<Container className="px-4" fluid>
						<Row className="justify-content-center">
							<Col className="page-content team-page">
								<h2>Officers</h2>
								<Row className="gx-5 gy-6  ">
									{data.allWpCommitteeMembers.nodes
										.filter(
											(item, index) =>
												item.committeeMemberFields.section === "Officers"
										)
										.map((member) => (
											<Col md={6} lg={4} xl={3}>
												<GatsbyImage
													style={{ height: "450px" }}
													class="mw-100 d-none d-md-block "
													image={
														member.committeeMemberFields?.image?.localFile
															.childImageSharp.gatsbyImageData
													}
													alt={
														member.committeeMemberFields?.image?.localFile
															.altText
													}
												/>
												<GatsbyImage
													style={{ height: "500px" }}
													class="mw-100  d-md-none  "
													image={
														member.committeeMemberFields?.image?.localFile
															.childImageSharp.gatsbyImageData
													}
													alt={
														member.committeeMemberFields?.image?.localFile
															.altText
													}
												/>
												<h3 className="fs-3 mt-3   ">
													{member.committeeMemberFields.title}
												</h3>
												<h4 className="fs-5  text-black ">
													{member.committeeMemberFields.name}
												</h4>
												<p style={{ fontSize: "90%" }}>
													{member.committeeMemberFields.bio}
												</p>
											</Col>
										))}
								</Row>
								<h2 className="mt-5">Office</h2>
								<Row className="gx-5 gy-6 justify-content-center">
									{data.allWpCommitteeMembers.nodes
										.filter(
											(item, index) =>
												item.committeeMemberFields.section === "Office"
										)
										.map((member) => (
											<Col md={6} lg={4} xl={3}>
												<GatsbyImage
													style={{ height: "450px" }}
													class="mw-100 d-none d-md-block "
													image={
														member.committeeMemberFields?.image?.localFile
															.childImageSharp.gatsbyImageData
													}
													alt={
														member.committeeMemberFields?.image?.localFile
															.altText
													}
												/>
												<GatsbyImage
													style={{ height: "500px" }}
													class="mw-100  d-md-none "
													image={
														member.committeeMemberFields?.image?.localFile
															.childImageSharp.gatsbyImageData
													}
													alt={
														member.committeeMemberFields?.image?.localFile
															.altText
													}
												/>
												<h3 className="fs-3 mt-3   ">
													{member.committeeMemberFields.title}
												</h3>
												<h4 className="fs-5  text-black ">
													{member.committeeMemberFields.name}
												</h4>
												<p style={{ fontSize: "90%" }}>
													{member.committeeMemberFields.bio}
												</p>
											</Col>
										))}
								</Row>
								<h2 className="mt-5">Trustees</h2>
								<Row className="gx-5 gy-6 ">
									{data.allWpCommitteeMembers.nodes
										.filter(
											(item, index) =>
												item.committeeMemberFields.section === "Trustees"
										)
										.map((member) => (
											<Col md={6} lg={4}>
												<GatsbyImage
													style={{ height: "450px" }}
													class="mw-100 d-none d-md-block d-lg-block d-xl-none "
													image={
														member.committeeMemberFields?.image?.localFile
															.childImageSharp.gatsbyImageData
													}
													alt={
														member.committeeMemberFields?.image?.localFile
															.altText
													}
												/>
												<GatsbyImage
													style={{ height: "500px" }}
													class="mw-100  d-md-none  "
													image={
														member.committeeMemberFields?.image?.localFile
															.childImageSharp.gatsbyImageData
													}
													alt={
														member.committeeMemberFields?.image?.localFile
															.altText
													}
												/>
												<GatsbyImage
													style={{ height: "600px" }}
													class="mw-100  d-none d-xl-block "
													image={
														member.committeeMemberFields?.image?.localFile
															.childImageSharp.gatsbyImageData
													}
													alt={
														member.committeeMemberFields?.image?.localFile
															.altText
													}
												/>

												<h4 className="fs-5 mt-3 text-black ">
													{member.committeeMemberFields.name}
												</h4>
												<p style={{ fontSize: "90%" }}>
													{member.committeeMemberFields.bio}
												</p>
											</Col>
										))}
								</Row>
								<Row>
									<Col lg={6}>
										<h2 className="mt-5">Patron</h2>
										<Row className="gx-5 gy-6 justify-content-center">
											{data.allWpCommitteeMembers.nodes
												.filter(
													(item, index) =>
														item.committeeMemberFields.section === "Patron"
												)
												.map((member) => (
													<Col md={7} lg={10} xl={8}>
														<GatsbyImage
															style={{ height: "500px" }}
															class="mw-100 d-xl-none "
															image={
																member.committeeMemberFields?.image?.localFile
																	.childImageSharp.gatsbyImageData
															}
															alt={
																member.committeeMemberFields?.image?.localFile
																	.altText
															}
														/>
														<GatsbyImage
															style={{ height: "600px" }}
															class="mw-100 d-none d-xl-block "
															image={
																member.committeeMemberFields?.image?.localFile
																	.childImageSharp.gatsbyImageData
															}
															alt={
																member.committeeMemberFields?.image?.localFile
																	.altText
															}
														/>

														<h3 className="fs-3 mt-3   ">
															{member.committeeMemberFields.title}
														</h3>
														<h4 className="fs-5  text-black ">
															{member.committeeMemberFields.name}
														</h4>
													</Col>
												))}
										</Row>
									</Col>
									<Col lg={6}>
										<h2 className="mt-5">Hon. Solicitor</h2>
										<Row className="g-6 justify-content-center">
											{data.allWpCommitteeMembers.nodes
												.filter(
													(item, index) =>
														item.committeeMemberFields.section ===
														"Hon. Solicitor"
												)
												.map((member) => (
													<Col md={7} lg={10} xl={8}>
														<GatsbyImage
															style={{ height: "500px" }}
															class="mw-100 d-xl-none"
															image={
																member.committeeMemberFields?.image?.localFile
																	.childImageSharp.gatsbyImageData
															}
															alt={
																member.committeeMemberFields?.image?.localFile
																	.altText
															}
														/>
														<GatsbyImage
															style={{ height: "600px" }}
															class="mw-100 d-none d-xl-block "
															image={
																member.committeeMemberFields?.image?.localFile
																	.childImageSharp.gatsbyImageData
															}
															alt={
																member.committeeMemberFields?.image?.localFile
																	.altText
															}
														/>

														<h4 className="fs-5 mt-3 text-black ">
															{member.committeeMemberFields.name}
														</h4>
													</Col>
												))}
										</Row>
									</Col>
								</Row>

								<h2 className="mt-5">Auditors</h2>
								<Row className="g-6 justify-content-center">
									{data.allWpCommitteeMembers.nodes
										.filter(
											(item, index) =>
												item.committeeMemberFields.section === "Auditors"
										)
										.map((member) => (
											<Col className="text-center" md={6} lg={4} xl={3}>
												<GatsbyImage
													class="mw-100 "
													image={
														member.committeeMemberFields?.image?.localFile
															.childImageSharp.gatsbyImageData
													}
													alt={
														member.committeeMemberFields?.image?.localFile
															.altText
													}
												/>
											</Col>
										))}
								</Row>
							</Col>
						</Row>
					</Container>
				</section>
				{/* <section className="pb-5">
					<Container>
						<Row className="justify-content-center">
							<Col xl={10} className="page-content team-page">
								{parse(data.wpPageContent.content)}
							</Col>
						</Row>
					</Container>
				</section> */}
				<section className="pt-6 d-none d-md-block">
					<Container fluid className="px-0 mx-0">
						<Row>
							<Col xs={12}>
								<GatsbyImage
									image={data.tImg.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
						</Row>
					</Container>
				</section>
			</Layout>
		</>
	);
};

export default ExecutiveCommitteePage;
